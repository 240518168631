<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="itemHeading1">Add Snake Details</span>
          </v-flex>
          <v-flex xs12 pt-3>
            <span class="heading1">Basic Information</span>
          </v-flex>
          <v-flex xs12>
            <!-- BASIC INFO -->
            <v-layout wrap justify-start>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Common Name</span>
                <v-text-field
                  outlined
                  placeholder="eg : king cobra"
                  v-model="commonname"
                  dense
                  hide-details
                >
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Scientic Name</span>
                <v-text-field
                  outlined
                  placeholder="eg : Ophiophagus Hannah "
                  v-model="scientificname"
                  dense
                  hide-details
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Other Name</span>
                <v-text-field
                  outlined
                  
                  v-model="othername"
                  dense
                  hide-details
                >
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>State</span>
                <v-select
                  :items="statelist"
                  outlined
                  dense
                  hide-details
                  label="Select State"
                  v-model="state"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Regional Name </span>
                <v-text-field
                  outlined
                  placeholder="eg : Naagraj (Hindi and Marathi); Rajavembala (Malayalam)"
                  v-model="reginonalname"
                  dense
                  hide-details
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <!-- OTHER INFO -->
            <v-layout wrap justify-start pt-4>
              <v-flex xs12 >
                <span class="heading1">Other Information</span>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 class="subheading">
                <span>Geographic Presence</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="distribution"
                ></vue-editor>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Venom Type</span>
                <v-select
                  :items="venom"
                  outlined
                  dense
                  hide-details
                  v-model="venomtype"
                >
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Family</span>
                <v-select
                  :items="familylist"
                  outlined
                  dense
                  hide-details
                  v-model="family"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Genus</span>
                <v-select
                  :items="genusitems"
                  outlined
                  dense
                  hide-details
                  v-model="genus"
                >
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Occurence</span>
                <v-select
                  :items="occurenceitems"
                  outlined
                  dense
                  hide-details
                  v-model="occurence"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>WLPA</span>
                <v-select
                  :items="WLPAitems"
                  outlined
                  dense
                  hide-details
                  v-model="wlpa"
                >
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>IUCN</span>
                <v-select
                  :items="IUCNitems"
                  outlined
                  dense
                  hide-details
                  v-model="iucn"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 class="subheading">
                <span>Scalation</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="scalation"
                ></vue-editor>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 class="subheading">
                <span>Description</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="description"
                ></vue-editor>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 class="subheading">
                <span>Short Description</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="shortdescription"
                ></vue-editor>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Assign to Expert</span>
                <v-select
                  :items="expertlist"
                  outlined
                  dense
                  hide-details
                  v-model="experts"
                  item-text="name"
                  item-value="_id"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6>
                <v-btn depressed color="#427D2D" @click="handlesubmit()">
                  <span style="color:white">Submit</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      commonname: "",
      scientificname: "",
      othername: "",
      state: "",
      reginonalname: "",
      distribution: "",
      venomtype: "",
      family: "",
      genus: "",
      occurence: "",
      wlpa: "",
      iucn: "",
      scalation: "",
      description: "",
      shortdescription: "",
      expertlist: [],
      experts: "",
      familylist: [
        "Acrochordidae",
        "Colubridae",
        "Elapidae",
        "Erycidae",
        "Gerrhopilidae",
        "Homalopsidae",
        "Natricidae",
        "Pareidae",
        "Pythonidae",
        "Sibynophiidae",
        "Typhlopidae",
        "Uropeltidae",
        "Viperidae",
      ],
      venom: ["Highly Venomous", "Mildly Venomous", "Non Venomous"],
      genusitems: [
        "Acrochordus",
        "Ahaetulla",
        "Amphiesma",
        "Atretium",
        "Boiga",
        "Bungarus",
        "Calliophis",
        "Cerberus",
        "Chrysopelea",
        "Coelognathus",
        "Daboia",
        "Dendrelaphis",
        "Dieurostus",
        "Dryocalamus",
        "Echis",
        "Eryx",
        "Fowlea",
        "Gerarda",
        "Gerrhopilus",
        "Grypotyphlops",
        "Hebius",
        "Hydrophis",
        "Hypnale",
        "Indotyphlops",
        "Liopeltis",
        "Lycodon",
        "Melanophidium",
        "Naja",
        "Oligodon",
        "Ophiophagus",
        "Platyceps",
        "Platyplectrurus",
        "Plectrurus",
        "Proahaetulla",
        "Ptyas",
        "Python",
        "Rhabdophis",
        "Rhabdops",
        "Rhinophis",
        "Sibynophis",
        "Teretrurus",
        "Trimeresurus (Craspedocephalus)",
        "Uropeltis",
        "Xylophis",
      ],
      occurenceitems: ["Common", "Uncommon", "Rare"],
      WLPAitems: [
        { value: "Schedule  -I", text: "Schedule  -I" },
        { value: "Schedule  -II", text: "Schedule  -II" },
        { value: "Schedule  -III", text: "Schedule  -III" },
        { value: "Schedule  -IV", text: "Schedule  -IV" },
        { value: "Not Listed", text: "Not Listed" },
        { value: "Vulnerable (VU)", text: "Vulnerable (VU)" },
        { value: "Near Threatened (NT)", text: "Near Threatened (NT)" },
        {
          value: "Conservation Dependent (CD)",
          text: "Conservation Dependent (CD)",
        },
        { value: "Extinct (EX)", text: "Extinct (EX)" },
        { value: "Extinct in the Wild (EW)", text: "Extinct in the Wild (EW)" },
      ],
      IUCNitems: [
        { value: "Not Evaluated (NE)", text: "Not Evaluated (NE)" },
        { value: "Data Deficient (DD)", text: "Data Deficient (DD)" },
        { value: "Least Concern (LC)", text: "Least Concern (LC)" },
        {
          value: "Critically Endangered (CR)",
          text: "Critically Endangered (CR)",
        },
        { value: "Endangered (EN)", text: "Endangered (EN)" },
        { value: "Vulnerable (VU)", text: "Vulnerable (VU)" },
        { value: "Near Threatened (NT)", text: "Near Threatened (NT)" },
        {
          value: "Conservation Dependent (CD)",
          text: "Conservation Dependent (CD)",
        },
        { value: "Extinct (EX)", text: "Extinct (EX)" },
        { value: "Extinct in the Wild (EW)", text: "Extinct in the Wild (EW)" },
      ],
      statelist: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarakhand",
        "West Bengal",
        "Andaman and Nicobar",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Lakshadweep",
        "Delhi",
        "Puducherry",
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/experts",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.expertlist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    handlesubmit() {
      if (!this.commonname) {
        this.msg = "Please Provide Name.";
        this.showSnackBar = true;
        return;
      }
      if (!this.scientificname) {
        this.msg = "Please Provide scientific name.";
        this.showSnackBar = true;
        return;
      }
      // if (!this.state) {
      //   this.msg = "Please Provide state.";
      //   this.showSnackBar = true;
      //   return;
      // }

      // if (!this.reginonalname) {
      //   this.msg = "Please Provide reginonal name.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.distribution) {
      //   this.msg = "Please Provide distribution.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.state) {
      //   this.msg = "Please Provide state.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.state) {
      //   this.msg = "Please Provide state.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.state) {
      //   this.msg = "Please Provide state.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.state) {
      //   this.msg = "Please Provide state.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.state) {
      //   this.msg = "Please Provide state.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.state) {
      //   this.msg = "Please Provide state.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.state) {
      //   this.msg = "Please Provide state.";
      //   this.showSnackBar = true;
      //   return;
      // }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/snake/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          name: this.commonname,
          scientificName: this.scientificname,
          states: this.state,
          rname: this.reginonalname,
          distribution: this.distribution,
          characteristics: this.scalation,
          description: this.description,
          venomType: this.venomtype,
          family: this.family,
          occurence: this.occurence,
          wlpa: this.wlpa,
          iucn: this.iucn,
          shortdes: this.shortdescription,
          othernames: this.othername,
          genus: this.genus,
          experts: this.experts,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.commonname = "";
              this.scientificname = "";
              this.state = "";
              this.reginonalname = "";
              this.distribution = "";
              this.scalation = "";
              this.description = "";
              this.venomtype = "";
              this.family = "";
              this.occurence = "";
              this.wlpa = "";
              this.iucn = "";
              this.shortdescription = "";
              this.othername = "";
              this.genus = "";
              this.experts = "";
              this.$router.push({ name: "listedSnakes" });
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
<style>

.subheading {
  font-family: sofiaProRegular;
  font-size: 16px;
  font-weight: 500;
}
</style>